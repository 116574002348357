import { PIMU_CATEGORIES_ROUTE, Biology_CATEGORIES_ROUTE, ECG_CATEGORIES_ROUTE, Endoscopy_CATEGORIES_ROUTE, Patanatomy_CATEGORIES_ROUTE, Gistology_CATEGORIES_ROUTE, KLD_CATEGORIES_ROUTE, Microbiology_CATEGORIES_ROUTE, SkinDisease_CATEGORIES_ROUTE, Modelling_CATEGORIES_ROUTE } from '../utils/consts';
import { fetchBiology_Categories, fetchOneBiology_Data, fetchBiology_Data } from "../http/Data_API/Biology_Data_API";
import { fetchPimu_Categories } from '../http/Data_API/Pimu_Categories_Data_API';
import { fetchECG_Categories, fetchOneECG_Data, fetchECG_Data, findECG_Data_Con_Parameters, findECG_Data_OptionsDefault, findECG_Data_Options } from '../http/Data_API/ECG_Data_API';
import { fetchEndoscopy_Categories, fetchOneEndoscopy_Data, fetchEndoscopy_Data } from '../http/Data_API/Endoscopy_Data_API';
import { fetchPatanatomy_Categories, fetchOnePatanatomy_Data, fetchPatanatomy_Data } from '../http/Data_API/Patanatomy_Data_API';
import { fetchGistology_Categories, fetchOneGistology_Data, fetchGistology_Data } from '../http/Data_API/Gistology_Data_API';
import { fetchKLD_Categories, fetchOneKLD_Data, fetchKLD_Data } from '../http/Data_API/KLD_Data_API';
import { fetchMicrobiology_Categories, fetchMicrobiology_Data, fetchOneMicrobiology_Data } from '../http/Data_API/Microbiology_Data_API';
import { fetchSkinDisease_Categories, fetchSkinDisease_Data, fetchOneSkinDisease_Data } from '../http/Data_API/SkinDisease_Data_API';
import { fetchModelling_Categories, fetchModelling_Data, fetchOneModelling_Data } from '../http/Data_API/Modelling_Data_API';
import { fetchGender } from '../http/Data_API/Gender_Data_API';

//  Таблица соответствия названию категории и функциям, получающим данные для неё.
const FetchTable = {
    //  ЭКГ
    'ecg': {
        'category': fetchECG_Categories,
        'items': fetchECG_Data,
        'item': fetchOneECG_Data,
    },
    //  Эндоскопия
    'endoscopy': {
        'category': fetchEndoscopy_Categories,
        'items': fetchEndoscopy_Data,
        'item': fetchOneEndoscopy_Data,
    },
    //  Паразитология
    'biology': {
        'category': fetchBiology_Categories,
        'items': fetchBiology_Data,
        'item': fetchOneBiology_Data,
    },
    //  Паталогическая анатомия
    'patanatomy': {
        'category': fetchPatanatomy_Categories,
        'items': fetchPatanatomy_Data,
        'item': fetchOnePatanatomy_Data,
    },
    //  Гистология
    'gistology': {
        'category': fetchGistology_Categories,
        'items': fetchGistology_Data,
        'item': fetchOneGistology_Data,
    },
    //  КЛД
    'kld': {
        'category': fetchKLD_Categories,
        'items': fetchKLD_Data,
        'item': fetchOneKLD_Data,
    },
    //  Микробиология
    'microbiology': {
        'category': fetchMicrobiology_Categories,
        'items': fetchMicrobiology_Data,
        'item': fetchOneMicrobiology_Data,
    },
    //  Заболевания кожи
    'skindisease': {
        'category': fetchSkinDisease_Categories,
        'items': fetchSkinDisease_Data,
        'item': fetchOneSkinDisease_Data,
    },
    //  Моделирование
    'modelling': {
        'category': fetchModelling_Categories,
        'items': fetchModelling_Data,
        'item': fetchOneModelling_Data,
    }
};

//  Ссылки на константы для навигации по подкатегории.
const SubCategoryRoute = {
    'ecg': ECG_CATEGORIES_ROUTE,
    'endoscopy': Endoscopy_CATEGORIES_ROUTE,
    'biology': Biology_CATEGORIES_ROUTE,
    'patanatomy': Patanatomy_CATEGORIES_ROUTE,
    'gistology': Gistology_CATEGORIES_ROUTE,
    'kld': KLD_CATEGORIES_ROUTE,
    'microbiology': Microbiology_CATEGORIES_ROUTE,
    'skindisease': SkinDisease_CATEGORIES_ROUTE,
    'modelling': Modelling_CATEGORIES_ROUTE,
};

//  Общая функция для подготовки данных страницы.
//  На вход необходимо передать:
//  *   location -- объект с информацией о текущем пути (pathname и search будут использованы);
//  *   language -- текущий язык;
//  *   search -- опционально, поисковой запрос;
//  *   page -- номер страницы, на которой находится пользователь;
//  *   limit -- максимальное кол-во элементов на странице (по умолчанию: 10 элементов);
//  *   gender -- идентификатор выбранного пола;
//  *   entitiesId -- идентификатор entities;
//  *   ages -- массив с максимальным и минимальным значениями возраста.
//
//  В результате выполнения функция вернёт объект с полной информацией о текущей странице.
//  В объекте будут ключи:
//  *   primaryCategory -- объект с информацией о первичной категории пути (экг, эндоскопия, т.д.);
//  *   subCategory -- объект с информацией о подкатегории;
//  *   items -- опционально, здесь будут элементы категории (кейсы).
export const PreparePage = async (location, language, search, page, limit = 10, gender = null, entitiesId = null, ages = []) => {
    let [ categoryName, categoryId ] = location.pathname.slice(1, location.pathname.endsWith('/') ? -1 : location.pathname.length).split('/');
    const searchParams = new URLSearchParams(location.search);

    if (categoryName == 'pimu')
    {
        categoryName = categoryId;
        categoryId = null;
    }

    const pimuCategories = await fetchPimu_Categories();
    const thisPrimaryCategory = pimuCategories.find( ({ path }) => path == categoryName );

    let matchingCategories = [];
    search = JSON.parse(JSON.stringify(search));
    search = typeof search == 'string' ? search.trim() : null;

    if (search)
        matchingCategories = await ( FetchTable[categoryName].category(search, language) );

    const subCategories = await ( FetchTable[categoryName].category(null, language) );
    let thisSubCategory;
    if (categoryId)
        thisSubCategory = subCategories.find( ({ id }) => id == categoryId );
    else
        thisSubCategory = subCategories;

    if (searchParams.has('page'))
        page = parseInt(searchParams.get('page'));

    let items = null;
    switch (categoryName)
    {
        case 'ecg':
            items = await ( FetchTable[categoryName].items(search, language, gender, categoryId, ages, page, limit) );
            break;
        case 'gistology':
            items = await ( FetchTable[categoryName].items(search, language, entitiesId, gender, categoryId, page, limit) );
            break;
        case 'kld':
            items = await ( FetchTable[categoryName].items(search, language, gender, categoryId, ages, page, limit) );
            break;
        default:
            items = FetchTable[categoryName].items !== undefined ? await ( FetchTable[categoryName].items(search, language, categoryId, page, limit) ) : null;
            break;
    }

    return {
        primaryCategory: {
            ...thisPrimaryCategory,
            route: PIMU_CATEGORIES_ROUTE,
        },
        subCategory: {
            ...thisSubCategory,
            route: SubCategoryRoute[categoryName],
        },
        search: {
            succeeded: search != null ? matchingCategories.length > 0 : null,
            matches: matchingCategories,
        },
        'items': {
            count: items.count,
            rows: items?.rows,
            limit: limit
        },
    };
};

//  Общая функция для подготовки данных конкретной страницы с кейсом.
//  Аргументы:
//  *   location -- объект с информацией о текущем пути (pathname будет использован);
//  *   language -- текущий язык (по умолчанию - русский);
//  *   gender -- какой пол был выбран на странице в списке категорий (где применимо);
//  *   limit -- максимальное количество записей, которое было на странице в списке категорий (нужно для расчёта страницы в навигации по кейсам).
//  Эта функция загрузит информацию о связанной с этим элементом категории, а также информацию о следующем и предыдущем кейсе.
//  Она _не_ учитывает уровень доступа пользователя, так задумано. Проверять доступ пользователя к кейсам должна вызывающая функция, обрабатывающая результат.
//  TODO: аргумент limit тут явно не к месту, т.к. он нужен лишь посредственно. Вынести логику пагинации куда-то?
export const PrepareItemPage = async (location, language = 'ru', gender = null, limit = 10) => {
    if (!location)
        return;

    const [ categoryName, entrie, entryId ] = location.pathname.slice(1, location.pathname.endsWith('/') ? -1 : location.pathname.length).split('/');

    const itemData = FetchTable[categoryName] !== undefined ? await (FetchTable[categoryName].item(entryId)) : null;

    if (itemData?.images_path != null)
        itemData.images_path = itemData.images_path.split('|');

    if (itemData?.images_path_dzi != null)
        itemData.images_path_dzi = itemData.images_path_dzi.split('|').map( (v) => `/${v.replaceAll("\\", "\/")}` );

    if (itemData?.gender != null)
    {
        const genders = await fetchGender();
        itemData.gender = genders.find( ({ id }) => id == itemData.gender );
    }

    const subCategories = await ( FetchTable[categoryName].category(null, null) );
    const thisSubCategory = subCategories.find( ({ id }) => id == itemData.categories_id );

    const GenderUnspecified = 3;

    let thisSubCategoryItems = null;
    switch (categoryName)
    {
        case 'ecg':
            thisSubCategoryItems = await ( FetchTable[categoryName].items(null, language, gender == GenderUnspecified ? null : gender, thisSubCategory.id, null, null, null) );
            itemData.ecg_data_con_parameters = await findECG_Data_Con_Parameters(itemData.id);
            itemData.ecg_optionsDefault = await findECG_Data_OptionsDefault(itemData.gender.id, itemData.categories_id);
            itemData.ecg_options = await findECG_Data_Options(itemData.id);
            break;
        case 'gistology':
            thisSubCategoryItems = await ( FetchTable[categoryName].items(null, language, null, gender == GenderUnspecified ? null : gender, thisSubCategory.id, null, null) );
            break;
        case 'kld':
            thisSubCategoryItems = await ( FetchTable[categoryName].items(null, language, gender == GenderUnspecified ? null : gender, thisSubCategory.id, null, null, null) );
            break;
        default:
            thisSubCategoryItems = await ( FetchTable[categoryName].items(null, language, thisSubCategory.id, null, null) );
            break;
    }

    let nextItem = null;
    let previousItem = null;

    const PageByIndex = (index, limit, count) => {
        return Math.ceil((index + 1) / limit);
    }

    for (let index = 0; index < thisSubCategoryItems.rows.length; index++)
    {
        if (thisSubCategoryItems.rows[index].id == entryId)
        {
            itemData.page = PageByIndex(index, limit, thisSubCategoryItems.rows.length);

            if (index > 0 && thisSubCategoryItems.rows[index - 1] !== undefined)
            {
                previousItem = {
                    ...thisSubCategoryItems.rows[index - 1],
                    page: PageByIndex(index - 1, limit, thisSubCategoryItems.rows.length),
                };
            }

            if (thisSubCategoryItems.rows[index + 1] !== undefined)
            {
                nextItem = {
                    ...thisSubCategoryItems.rows[index + 1],
                    page: PageByIndex(index + 1, limit, thisSubCategoryItems.rows.length),
                };
            }

            break;
        }
    }

    return {
        category: {
            ...thisSubCategory,
            route: categoryName,
        },
        item: itemData,
        nextItem: nextItem,
        previousItem: previousItem,
    };
};

//  Функция-помощник для склонения числительных.
//  Первым аргументом идёт само число,
//  вторым аргументом ожидается массив для значений: 1, 2, 5.
export const ClenseNumeral = (val, words) => {
    val = Math.abs(val) % 100;
    const d = val % 10;
  
    if (val > 10 && val < 20) return words[2];
    if (d > 1 && d < 5) return words[1];
    if (d == 1) return words[0];
  
    return words[2];
  };