import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

const Map_Page = observer(() => {
    const history = useNavigate()


    return (
        <div>
            карта
        </div>
    );
});

export default Map_Page;