import React, { useContext, useEffect, useState } from 'react';
import { Card, Container } from "react-bootstrap";
import SkinDisease_Data_EntriesList from "../../components/SkinDisease/SkinDisease_Data_EntriesList";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Context } from "../../index";
import Pages from "../../components/Pages";
import { PreparePage } from '../../utils/PageDataPrepare';
import { useLocation } from 'react-router-dom';
import NavigationBreadcrumbs from '../../components/NavigationBreadcrumbs';
import { SkinDisease_EntriesPerPage } from './SkinDisease_Categories_Page';
import Patanatomy_Data_EntriesList from "../../components/Patanatomy/Patanatomy_Data_EntriesList";

const SkinDisease_Data_EntriesTable_Page = observer(() => {
    const { user } = useContext(Context);
    const location = useLocation();
    const { t } = useTranslation();
    const [pageData, setPageData] = useState({});

    useEffect(() => {
        PreparePage(location, user.language, user.search, user.page, SkinDisease_EntriesPerPage).then((data) => setPageData(data));
    }, [location, user]);

    if (!pageData)
        return;

    return <>
        <div className='page_content_title'>
            <img className='page_title_icon' src='/icons/hands_icon.png' alt='page_title_icon'></img>{t("skindisease_title")}
        </div>
        <Container>
            <NavigationBreadcrumbs categoryInfo={pageData} language={user.language} />
            <div className="mt-2">
                <div md={9}>
                    <Patanatomy_Data_EntriesList pageData={pageData} />
                    <Pages pageData={pageData} onPageChanged={(page) => user.page = page} />
                </div>
            </div>
        </Container>
    </>;
});

export default SkinDisease_Data_EntriesTable_Page;
