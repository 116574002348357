import React, { useContext, useState } from 'react';
import { Context } from "../index";
import './ECG/ECG_Categories_CollapseDisplay.css'
import './ECG/ECG_Categories_ButtonDisplay.css'
import { useNavigate, useLocation } from 'react-router-dom';
import * as consts from '../utils/consts';
import { useTranslation } from 'react-i18next';

const CategoriesRoutes = {
    'endoscopy': consts.Endoscopy_CATEGORIES_ROUTE,
    'ecg': consts.ECG_CATEGORIES_ROUTE,
    'biology': consts.Biology_CATEGORIES_ROUTE,
    'patanatomy': consts.Patanatomy_CATEGORIES_ROUTE,
    'gistology': consts.Gistology_CATEGORIES_ROUTE,
    'kld': consts.KLD_CATEGORIES_ROUTE,
    'microbiology': consts.Microbiology_CATEGORIES_ROUTE,
    'skindisease': consts.SkinDisease_CATEGORIES_ROUTE,
    'modelling': consts.Modelling_CATEGORIES_ROUTE,
};

const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'instant' });
};

//  Элемент с подписью, обозначающей, что в этой категории есть демо кейсы
const HasDemoLabel = ({ t }) => {
    return <span className='badge rounded-pill text-bg-primary fw-normal'>{t('Demo_short').toLocaleLowerCase()}</span>;
};

const SidebarCategoriesDisplay_Button = ({ t, categoryName, item }) => {
    const { user } = useContext(Context);
    const history = useNavigate();

    const GoToSubCategory = (event) => {
        scrollToTop();
        user.gender = null;
        history(CategoriesRoutes[categoryName] + '/' + item.id + '?page=1&gender=3');
        return;
    };

    if (!item || !categoryName)
        return;

    return <div className="ecg_card-list">
        <button className='ecg_card-items' key={item.id} onClick={GoToSubCategory}>
            <div className="ecg_card-name">
                <div className='row'>
                    <div className='col-xl'>
                        {item['name_' + user.language]}
                    </div>
                    <div className='col-auto'>
                        {user?.access_level?.demo_access && Number(item.totalDemoCases) > 0 && <HasDemoLabel t={t} />}
                    </div>
                </div>
            </div>
        </button>
    </div>;
};

const SidebarCategoriesDisplay_List = ({ t, categoryName, items }) => {
    if (!items)
        return;

    return <div className='ekg_diagnoses_list-block'>
        {items.map((item) => <SidebarCategoriesDisplay_Button key={item.id} t={t} categoryName={categoryName} item={item} />)}
    </div>;
};

const SidebarCategoriesDisplay = ({ categories, matchingCategories = [], currentSubLevel = 0 }) => {
    const { user } = useContext(Context);
    const { pathname } = useLocation();
    const [pimuCategory, categoryName] = pathname.substring(1).split('/');
    const history = useNavigate();
    const { t } = useTranslation();

    const [isCollapsed, setIsCollapsed] = useState(null);

    const ToggleCollapse = (categoryId) => {
        if (isCollapsed == categoryId)
            setIsCollapsed(null);
        else
            setIsCollapsed(categoryId);
    };

    const NavigateToSubCategory = (categoryId) => {
        scrollToTop();
        user.gender = null;
        history(`${CategoriesRoutes[categoryName]}/${categoryId}?page=1&gender=3`);

        return;
    };

    if (!categories)
        return;

    let categoriesArray = categories instanceof Array ? categories : Object.keys(categories).map((key) => categories[key]);
    let firstLevelCategories;

    //  Привести объекты массива категорий к одному типу.
    categoriesArray = categoriesArray.map((category) => { return { ...category, sublevel: category?.sublevel === undefined ? 0 : category.sublevel }; });

    //  Выделить из всего массива категорий те, что находятся на "первом уровне".
    firstLevelCategories = categoriesArray.filter(({ id, sublevel }) => id !== undefined && sublevel == currentSubLevel);

    //  Если были заданы какие-то условия поиска, то оставить из всех категорий первого уровня и их подкатегорий лишь подходящие.
    if (matchingCategories?.length) {
        firstLevelCategories = categoriesArray.filter((category) => {
            return category.sublevel !== undefined &&
                category.sublevel == currentSubLevel &&
                matchingCategories.find(({ id, position }) => category.position === undefined ? id == category.id : category.position[0] == position[0]) !== undefined
        });
        categoriesArray = categoriesArray.filter((category) => matchingCategories.find(({ id }) => id == category.id));
    }

    //  Пройтись по всем категориям "первого уровня" и узнать, сколько у их подкатегорий демо кейсов.
    //  TODO: конечно же, лучше это перенести с sql запрос, дабы не высчитывать это здесь.
    firstLevelCategories.map((category) => {
        const childCategories = categoriesArray.filter((subCategory) => {
            return subCategory.sublevel !== undefined && subCategory.sublevel > category.sublevel && subCategory.position[0] == category.position[0];
        });

        if (childCategories.length) {
            const totalDemoCases = childCategories.reduce((prev, curr) => prev + Number(curr.totalDemoCases), 0);
            category.totalDemoCases = totalDemoCases;
        }
    });

    return <div className='card_list'>
        {firstLevelCategories.map((category) => <div className='card_flex' key={category.id}>
            <button className='card_items' onClick={() => category.sublevels_count !== undefined && category.sublevels_count !== 0 ? ToggleCollapse(category.id) : NavigateToSubCategory(category.id)}>
                <div className='card-item-name'>
                    <div className='row'>
                        <div className='col-xl'>
                            {category['name_' + user.language]}
                        </div>
                        <div className='col-auto'>
                            {user?.access_level?.demo_access && Number(category.totalDemoCases) > 0 && <HasDemoLabel t={t} />}
                        </div>
                    </div>
                </div>
            </button>
            {isCollapsed == category.id && <div>
                {categoriesArray.filter(({ sublevels_count, sublevel, position }) => sublevels_count > 0 && sublevel == category.sublevel + 1 && position.startsWith(category.position.substring(0, 3))).map((subCategory) => {
                    return <SidebarCategoriesDisplay currentSubLevel={subCategory.sublevel} matchingCategories={matchingCategories} categories={[subCategory, ...categoriesArray.filter(({ position, sublevel }) => position && position.startsWith(subCategory.position.substring(0, 3)) && sublevel == subCategory.sublevel + 1)]} />;
                })}
                <SidebarCategoriesDisplay_List t={t} categoryName={categoryName} items={categoriesArray.filter(({ position, sublevel, sublevels_count }) => position && position.startsWith(category.position.substring(0, 2)) && sublevel == currentSubLevel + 1 && sublevels_count == 0)} />
            </div>}
        </div>)}
    </div>;
};

export default SidebarCategoriesDisplay;