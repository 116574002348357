import { useContext, useState, useEffect } from 'react';
import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import "./SkinDisease_Categories_Page.css"
import { useTranslation } from "react-i18next";
import Search_Bar from "../../components/Search_Bar";
import { useLocation } from 'react-router-dom';
import { PreparePage } from '../../utils/PageDataPrepare';
import SidebarCategoriesDisplay from '../../components/SidebarCategoriesDisplay';

export const SkinDisease_EntriesPerPage = 8;

const SkinDisease_Categories_Page = observer(() => {
    const { user } = useContext(Context);
    const { t } = useTranslation();
    const location = useLocation();
    const [ pageData, setPageData ] = useState({});

    useEffect( () => {
        PreparePage(location, user.language, user.search, user.page).then( (data) => setPageData(data) );
    }, [ location, user, user.search ]);

    if (!pageData || !pageData?.subCategory)
        return;

    return (
        <div className='page_content'>
            <div className='page_content_title'>
                <img className='page_title_icon' src='/icons/hands_icon.png' alt='page_title_icon'></img>{t("skindisease_title")}
            </div>
            <div className='page_content_parent'>
                <div className='page_content_flex'>
                    <SidebarCategoriesDisplay categories={pageData.subCategory} matchingCategories={pageData.search?.matches} />
                </div>
                <div className='page_content_rightpage_block'>
                    <Search_Bar searchSucceeded={pageData.search?.succeeded} />
                    <div className='page_content_rightpage'>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SkinDisease_Categories_Page;