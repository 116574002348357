import React, { useContext, ReactNode, useEffect, useState } from "react";
import { Context } from "../index";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink, redirect } from "react-router-dom";
import { LOGIN_ROUTE, LOGOUT_ROUTE, PIMU_CATEGORIES_ROUTE, SUPPORT_ROUTE, ABOUT_APPLICATION_ROUTE } from "../utils/consts";
import { observer } from "mobx-react-lite";
import { useNavigate } from 'react-router-dom'
import './NavBar.css'
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import { LanguageKeyName, DefaultLanguage, LanguagesData } from "../i18n";

const NavBar = observer(() => {
    const { user } = useContext(Context);
    const history = useNavigate();
    const [isMenuOpened, setIsMenuOpened] = useState(false);

    //  Роль пользователя. Если её нет - то пользователь демо.
    const userRole = user.profile.role ?? 'demo';

    const logOut = () => {
        window.location.replace(LOGOUT_ROUTE);
        // user.setUser({});
        // user.setIsAuth(false);

        // localStorage.removeItem('token');
        // history(LOGIN_ROUTE);
    };

    //  Работа с локализацией.
    const { t, i18n } = useTranslation();
    //  State с информацией о текущем языке. Объект содержит в себе всю нужную информацию - картинка, название, код...
    //  По скольку это инициализируется в файле i18n.js, то мы берём лишь значение из 'localStorage' либо по умолчанию, если такого нет.
    const [currentLanguage, setCurrentLanguage] = useState(LanguagesData.find(({ code }) => code == (localStorage.getItem(LanguageKeyName) ?? DefaultLanguage)));

    //  Смена языка.
    //  При смене языка, которое происходит, когда пользователь нажимает на кнопку-иконку вверху с названием языка,
    //  нужно обновить значение в localStorage.
    const ChangeLanguage = (event) => {
        setCurrentLanguage((current) => {
            const currentLanguageIndex = LanguagesData.findIndex(({ code }) => code == current.code);
            if (LanguagesData[currentLanguageIndex + 1] === undefined)
                return LanguagesData[0];
            else
                return LanguagesData[currentLanguageIndex + 1];
        });
    };

    //  При смене языка - отправить "уведомление" объекту работы с локализацией о том, что язык изменился, чтобы текст на странице динамически обновился.
    useEffect(() => {
        i18n.changeLanguage(currentLanguage.code);
        localStorage.setItem(LanguageKeyName, currentLanguage.code);
        user.setLanguage(currentLanguage.code);
    }, [currentLanguage]);

    const toggleMenu = () => {
        setIsMenuOpened(!isMenuOpened);
    }

    const toggleSidebar = () => {
        const menuList = document.querySelector('.menu-list');
        menuList.classList.toggle('opened');
    };

    const handleSidebarLinkClick = () => {
        toggleSidebar();
        toggleMenu();
    };

    return (
        <Navbar className='navbar_block'>
            <div className='item1'>
                <a href="/" target="_blank" rel="noopener noreferrer">
                    <img src={currentLanguage.image} alt="main" />
                </a>
            </div>
            <button className='switch_language_button_navbar' onClick={ChangeLanguage}>
                <img className="icon_flag" src={currentLanguage.icon} alt={currentLanguage.code} />&nbsp;{currentLanguage.name}
            </button>
            <div className='item2'>
                <NavLink className="navbar_title" to={PIMU_CATEGORIES_ROUTE}>
                    {t('navbar_title_main')}
                </NavLink>
                <NavLink className="navbar_title" to={ABOUT_APPLICATION_ROUTE}>
                    {t('navbar_title_about')}
                </NavLink>
                <NavLink className="navbar_title" to={SUPPORT_ROUTE}>
                    {t('navbar_title_support')}
                </NavLink>
                {user.isAuth && userRole != 'demo' ? (
                    <>
                        <NavLink className='navbar_title' to='/testing'>{t('navbar_title_testing')}</NavLink>
                        <NavLink className='navbar_title' to='/account'>{t('navbar_title_account')}</NavLink>
                        <NavLink className="navbar_title" onClick={() => logOut()}>
                            {t('navbar_title_authorization_logout')}
                        </NavLink>
                    </>
                ) : (
                    <NavLink className="navbar_title" to={LOGIN_ROUTE}>
                        {t('navbar_title_authorization_login')}
                    </NavLink>
                )}
            </div>
            <div className='burger-menu'>
                <img className="burger-icon" onClick={toggleMenu} src="/icons/Menu@2x.png" alt="menu icon"></img>
                <div className={`menu-list ${isMenuOpened ? 'opened' : ''}`} >
                    <img className="close-btn" onClick={toggleMenu} src="/icons/Close_round_duotone@2x.png" alt="close icon"></img>
                    <div className="menu_sidebar_title">
                        {t('navbar_title_main')}
                    </div>
                    <NavLink className="navbar_title" to={PIMU_CATEGORIES_ROUTE} onClick={handleSidebarLinkClick}>
                        <img src='/icons/Home_fill@2x.png' alt="home icon"></img>
                        {t('navbar_title_main')}
                    </NavLink>
                    <NavLink className="navbar_title" to={ABOUT_APPLICATION_ROUTE} onClick={handleSidebarLinkClick}>
                        <img src='/icons/info_fill@2x.png' alt="info icon"></img>
                        {t('navbar_title_about')}
                    </NavLink>
                    <NavLink className="navbar_title" to={SUPPORT_ROUTE} onClick={handleSidebarLinkClick}>
                        <img src='/icons/Setting_fill@2x.png' alt="support icon"></img>
                        {t('navbar_title_support')}
                    </NavLink>
                    {user.isAuth && userRole != 'demo' ? (
                        <>
                            <NavLink className='navbar_title' to='/testing' onClick={handleSidebarLinkClick}>
                            <img src='/icons/Testing_fill@2x.png' alt='testing icon' />
                            {t('navbar_title_testing')}
                            </NavLink>
                            <NavLink className='navbar_title' to='/account' onClick={handleSidebarLinkClick}>
                                <img src='/icons/Account_menu.png' alt="account icon"></img>
                                {t('navbar_title_account')}
                            </NavLink>
                            <NavLink className="navbar_title" onClick={() => { logOut(); handleSidebarLinkClick() }}>
                                <img src='/icons/Sign_in_squre_fill@2x.png' alt="logout icon"></img>
                                {t('navbar_title_authorization_logout')}
                            </NavLink>
                        </>
                    ) : (
                        <NavLink className="navbar_title" to={LOGIN_ROUTE} onClick={handleSidebarLinkClick}>
                            <img src='/icons/Sign_in_squre_fill@2x.png' alt="login icon"></img>
                            {t('navbar_title_authorization_login')}
                        </NavLink>
                    )}
                </div>
            </div>
        </Navbar>
    );

});

export default NavBar;
