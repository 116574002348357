import { useState, useContext, useEffect } from 'react';
import './Quiz.css';
import { Context } from "../index";
import { useTranslation } from 'react-i18next';
import DZI_Images_Display from './DZI_OpenSeadragon/DZI_Images_Display';
import ECG_Data_Images_Display from './ECG/ECG_Data_Images_Display';

//  Компонент для кнопки вопроса.
//  Вынесен отдельно т.к. стало набираться слишком много логики, которую нужно обрабатывать.
const QuizButton = ({ option, language, isClickable, isDisabled, showCorrect, onAnswered }) => {
    const buttonClassNames = [
        'button'
    ];

    if (!isClickable && showCorrect)
        buttonClassNames.push(option.correct ? 'correct' : 'incorrect');

    return <button data-id={option.id} disabled={isDisabled || !isClickable} className={buttonClassNames.join(' ')} onClick={onAnswered}>
        {option.text[language]}
    </button>;
};

const QuizComponent = ({ testData, showCorrect, onAnswer }) => {
    const { t } = useTranslation();
    const { user } = useContext(Context);
    const [selectedAnswers, setSelectedAnswers] = useState([]);

    if (!testData)
        return;

    let quizOptions = [];
    const isDemo = user.access_level.demo_access;

    if (!isDemo) {
        quizOptions = testData.answers.map((a) => {
            return {
                ...a,
                correct: testData.correct.includes(a.id),
                type: 'button',
            }
        });
    }
    else {
        quizOptions = [
            {
                id: 1,
                text: {
                    ru: 'Вариант ответа 1',
                    en: 'Answer 1',
                },
                correct: true,
                type: 'button'
            },
            {
                id: 2,
                text: {
                    ru: 'Вариант ответа 2',
                    en: 'Anser 2'
                },
                correct: false,
                type: 'button'
            },
            {
                id: 3,
                text: {
                    ru: 'Вариант ответа 3',
                    en: 'Answer 3'
                },
                correct: false,
                type: 'button',
            }
        ];
    }

    const correctAnswers = quizOptions.filter(({ correct }) => correct === true).length;

    const onAnswered = (event) => {
        const answerId = event.target.dataset.id;
        if (!answerId || isDemo)
            return;

        const answerData = quizOptions.find(({ id }) => id == answerId);
        if (!answerData)
            return;

        setSelectedAnswers( (prev) => {
            const updatedAnswers = [ ...prev, answerData ];

            if (updatedAnswers.length == correctAnswers)
                onAnswer(updatedAnswers);

            return updatedAnswers;
        });
    };

    const Results = () => {
        if (selectedAnswers.length != correctAnswers)
            return;

        const answersSelected = selectedAnswers.reduce((prev, curr) => prev = [...prev, curr.text[user.language]], []).join(', ');
        const correctAnswersText = quizOptions.filter(({ correct }) => correct == true).reduce((prev, curr) => prev = [...prev, curr.text[user.language]], []).join(', ');
        const answeredCorrectly = selectedAnswers.reduce((prev, curr) => prev += curr.correct ? 1 : 0, 0);

        let answerResultHintToken;
        if (answeredCorrectly == correctAnswers)
            answerResultHintToken = 'quiz_answer_correct_fully';
        else
            if (answeredCorrectly >= 1)
                answerResultHintToken = 'quiz_answer_correct_partial';
            else
                answerResultHintToken = 'quiz_answer_incorrect';

        return <span>
            {t('quiz_answer_text_selected')}&nbsp;{answersSelected}.
            <br />
            {t(answerResultHintToken)}
            <br />
            {answeredCorrectly != correctAnswers && `${t('quiz_answer_correct_list')} ${correctAnswersText}`}
        </span>;
    };

    return <div className={`quiz${isDemo ? ' demo' : ''}`}>
        <div className='heading'>
            {testData.question[user.language]}
        </div>
        <div className='image'>
            {testData?.images_path && testData?.images_path[0].endsWith('.dzi') ? <DZI_Images_Display images={testData?.images_path} /> : <ECG_Data_Images_Display imageurl={testData?.images_path} imagesPerView={1} /> }
        </div>
        <div className='hint'>
            {t('quiz_hint_answers')}&nbsp;
            {correctAnswers > 1 ? t('quiz_hint_answers_multiple') : t('quiz_hint_answers_one')}
        </div>
        <div className='options'>
            {quizOptions.map((option) => <QuizButton key={option.id} option={option} onAnswered={onAnswered} language={user.language} isClickable={selectedAnswers.length != correctAnswers} isDisabled={selectedAnswers.find( ({ id }) => id == option.id )} showCorrect={showCorrect} /> )}
        </div>
        {showCorrect && <div className='results'>
            <Results />
        </div> }
        {isDemo && <div className='demo_text'>
            {t('quiz_demo_text', { returnObjects: true }).map((v, i) => <span key={i}><span dangerouslySetInnerHTML={{ __html: v }}></span><br /></span>)}
        </div>}
    </div>
};

export default QuizComponent;