import { useContext } from "react";
import { Context } from "../index";
import { useNavigate } from 'react-router-dom';
import { Biology_CATEGORIES_ROUTE } from "../utils/consts";

const HasDemoBadge = () => {
    return <span className='badge rounded-pill text-bg-primary fw-normal'>
        демо
    </span>
};

const BottomCategoryButton = ({ category }) => {
    const { user } = useContext(Context);
    const history = useNavigate();

    if (!category)
        return;

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'instant' });
    };

    const TryNavigateToCategory = () => {
        scrollToTop();
        history(`${Biology_CATEGORIES_ROUTE}/${category.id}?page=1`);

        return;
    }

    return <button className='biology_card-items' onClick={TryNavigateToCategory}>
        <div className='biology_card-icon'>
            <img src={category.icons} alt='Иконка категории' />
        </div>
        <div className='biology_card-name'>
            {category['name_' + user.language]}
            <br />
            {user?.access_level?.demo_access && Number(category.totalDemoCases) > 0 && <HasDemoBadge />}
        </div>
    </button>;
};

const BottomCategoriesDisplay = ({ categories, matchingCategories }) => {
    if (!categories)
        return;

    let categoriesArray = categories instanceof Array ? categories : Object.keys(categories).map( (name) => typeof categories[name] != 'string' ? categories[name] : null ).filter( (v) => v != null );

    if (matchingCategories?.length)
        categoriesArray = categoriesArray.filter( (category) => matchingCategories.find( ({ id }) => id == category.id ) );

    return <div className='biology_card-list'>
        {categoriesArray.map( (category) => <BottomCategoryButton key={category.id} category={category} /> )}
    </div>
};

export default BottomCategoriesDisplay;