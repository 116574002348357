import React, { useRef, useState, useEffect } from 'react';
import Microbiology_Data_Image from "./Microbiology_Data_Image";
import "./Microbiology_Data_Images_Display.css";
import Zoom from 'react-medium-image-zoom';

import Swiper from 'react-id-swiper';
import { Navigation, Pagination, Keyboard } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Microbiology_Data_Images_Display = ({ imageurl, onSlideChanged }) => {
    const swiperRef = useRef(null);
    const imageurldef = "/";
    const rows = imageurl.map((url) => (
        <div className="swiper-slide" key={url}>
            <Zoom>
                <Microbiology_Data_Image url={imageurldef + url} />
            </Zoom>
        </div>
    ));

    const SwiperHandler = {
        'slideChange': (swiper) => {
            if (onSlideChanged !== undefined)
            onSlideChanged(swiper.activeIndex);
        }
    };

    return (
        <div>
            <Swiper
                modules={[Navigation, Pagination, Keyboard]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next"
                }}
                pagination={{
                    el: ".swiper-pagination",
                    clickable: true
                }}
                keyboard={true}
                on={SwiperHandler}
            >
                {rows}

            </Swiper>
        </div>
    );
};

export default Microbiology_Data_Images_Display;